// Azure App registration details
export const AZURE_TENANT_ID = '2f7e641d-35dd-4a72-91ef-9c34810f3219';
export const APP_REGISTRATION_CLIENT_ID = '2941bc80-97f9-402f-b30c-8748d7c70d53';
export const APPLICATION_INSIGHTS_INSTRUMENTATION_KEY = '485c16b8-6968-4656-b3f2-ef0fa3959c56';

// API information
export const COSMOTECH_API_SCOPE = 'api://770009f5-774c-4f84-b7a9-4433a6c721df/platform';
export const DEFAULT_BASE_PATH = 'https://api.sams-ppd.nexans.com/v2';

// MiddleWare API
export const MIDDLEWARE_DEFAULT_BASE_PATH = 'https://api.sams-webapp-ppd.nexans.com';

// Cosmo solution identifiers
export const ORGANIZATION_ID = 'o-8yop30d38dk';
export const WORKSPACE_ID = 'w-qegnkjwq164';
export const POWER_BI_WORKSPACE_ID = '14e1d74c-5099-4486-8e5e-35b04ce0e156';
export const POWER_BI_API_DEFAULT_SCOPE = 'https://analysis.windows.net/powerbi/api/.default';

// Okta auth configuration
export const OKTA_CLIENT_ID = '0oa83hccbcVGR3FT4417';
export const OKTA_ISSUER = 'https://ciam.nexans.com/oauth2/aus8x4968cHAfCJ5l417';

// Power BI Report IDs
export const PBI_REPORT_IDS = {
  Scenario: {
    healthScoreModel: '6d903508-85bb-4737-871b-17ccb03712bc',
    effectiveAgeModel: '6d903508-85bb-4737-871b-17ccb03712bc'
  },
  Overview: '2e95a6d9-7bb3-4741-a1b9-b0dd1d135513',
  ScenarioComparison: '74a4d45e-dc62-4e35-baf4-411a85111870',
  ScenarioAnalysis: '56826978-a9ee-4294-a837-7650fcac3f64',
  OperationAnalysis: 'f260b9ec-3d5b-42f6-aac2-97a052b90646',
  CustomReports: 'e350aaae-53b6-4f58-bd04-ce1470bf5e03'
};

// Subset feature
export let ENABLE_SUBSET_FEATURE = 'false';
